import { navigate } from "gatsby"

const FourZeroFourPage = () => {
  if (typeof window !== `undefined`) {
    navigate("/")
  }
  return null
}

export default FourZeroFourPage
